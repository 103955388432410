import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './formation.component.html',
  styleUrls: ['./formation.component.less']
})
export class FormationComponent implements OnInit {
  public items: Array<FormationItem>;

  constructor() { 
    this.items = new Array<FormationItem>(
      {
        title: 'Signature Code - 2020 à Aujourd\'hui ( Alternance )',
        description: 'Développeur full-stack / .NET & Angular',
        isDiploma: false
      },
      {
        title: 'Bachelor informatique - 2020',
        isDiploma: true
      },
      {
        title: 'Infinite Square - 2019 ( Stage de 6 mois )',
        description: 'Développeur full-stack / .NET & Angular & ReactJS',
        isDiploma: false
      },
      {
        title: 'Leroy Merlin - 2017 à 2019 ( Job étudiant )',
        description: 'Conseiller de vente',
        isDiploma: false
      },
      {
        title: 'In\'Tech - 2017',
        description: 'Ingénierie logicielle',
        isDiploma: false
      },
      {
        title: 'SARL EXPERAUDIT - 2016 à 2017',
        description: 'Assistant comptable',
        isDiploma: false
      },
      {
        title: 'Hôpital Émile Roux - 2015 à 2016',
        description: 'Agent polyvalent de restauration',
        isDiploma: false
      },
      {
        title: 'BTS Comptabilité et Gestion des Organisations - 2015',
        isDiploma: true
      },
      {
        title: 'Baccalauréat Scientifique - 2012',
        isDiploma: true
      }
    );
  }

  ngOnInit(): void {
  }

}

export interface FormationItem {
  title: string;
  description?: string;
  isDiploma: boolean;
}