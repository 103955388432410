import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit {

  constructor( 
    private _router: Router,
    private _viewportScroller: ViewportScroller ) { 
  }

  ngOnInit(): void {
  }

  isSelected(path: string): boolean {
    return path === this._router.url;
  }

  goToHome() {
    this._router.navigate(['/']);
    this._viewportScroller.scrollToAnchor('landing');
  }
  
  goToProfile() {
    this._router.navigate(['/']);
    this._viewportScroller.scrollToAnchor('profile');
  }
  goToParcours() {
    this._router.navigate(['/parcours']);
  }
}
