import { ViewportScroller } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

@Component( {
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
} )
export class NavigationComponent implements OnInit {

  constructor ( private _router: Router,
    private _viewportScroller: ViewportScroller ) { }

  ngOnInit(): void {
  }

  isSelected( path: string ): boolean {
    if ( path.includes( 'realisations' ) || path.includes( 'competences' ) ) {
      return this._router.url.includes( path );
    }
    return path === this._router.url;
  }

  goToHome() {
    this._router.navigate( ['/'], { fragment: 'landing' } );
    this._viewportScroller.scrollToAnchor( 'landing' );
  }

  goToProfile() {
    this._router.navigate( ['/'], { fragment: 'profile' } );
  }

  goToParcours() {
    this._router.navigate( ['/parcours'] );
  }
}
