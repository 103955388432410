import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Article } from 'src/app/shared/models/article.model';
import { ArticleService } from 'src/app/shared/services/article.service';
import { filter } from 'rxjs/operators';

@Component( {
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.less']
} )
export class BlogListComponent implements OnInit {
  public realizations: Array<Article>;

  constructor (
    private _articleService: ArticleService,
    private _router: Router
  ) {
  }

  ngOnInit(): void {
    this._articleService.list$.pipe( filter( l => l !== undefined ) ).subscribe( list => {
      this.realizations = [...list.realizations];
    } );
  }

  selectRealization( realization: Article ) {
    this._articleService.setSelectedArticle( realization );
    this._router.navigate( [`/realisations/${realization.name}`] );
  }
}
