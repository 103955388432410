import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Article, ARTICLE_TYPE } from 'src/app/shared/models/article.model';

export interface SkillsList {
  softSkills: Array<Article>;
  techSkills: Array<Article>;
  realizations: Array<Article>;
}

@Injectable( {
  providedIn: 'root'
} )
export class ArticleService {
  private _selectedArticle: BehaviorSubject<Article | undefined>;
  public selectedArticle$: Observable<Article | undefined>;
  private _articles: Array<Article>;
  private _list: BehaviorSubject<SkillsList | undefined>;
  public list$: Observable<SkillsList | undefined>;

  constructor ( private _httpClient: HttpClient ) {
    this._selectedArticle = new BehaviorSubject<Article | undefined>( undefined );
    this.selectedArticle$ = this._selectedArticle.asObservable();
    this._list = new BehaviorSubject<SkillsList>( undefined );
    this.list$ = this._list.asObservable();

    this.loadArticles();
  }

  setSelectedArticle( article: Article ): void {
    this._selectedArticle.next( article );
  }

  setSelectedArticleByName( articleName: string ): void {
    if ( !this._articles ) {
      this._httpClient.get<SkillsList>( '/assets/articles/articles.json' ).subscribe( data => {
        this._articles = data.realizations.concat( data.softSkills ).concat( data.techSkills );
        this._list.next( data );
        const article = this._articles.find( a => a.name === articleName );
        this._selectedArticle.next( article );
      } );
    } else {
      const article = this._articles.find( a => a.name === articleName );
      this._selectedArticle.next( article );
    }
  }

  loadArticles(): void {
    this._httpClient.get<SkillsList>( '/assets/articles/articles.json' ).subscribe( data => {
      this._articles = data.realizations.concat( data.softSkills ).concat( data.techSkills );
      this._list.next( data );
    } );
  }

  getArticles( type: ARTICLE_TYPE ): Array<Article> {
    if ( !this._list.value ) {
      this.loadArticles();
    }
    if ( type === ARTICLE_TYPE.REALIZATION ) {
      return this._list.value.realizations;
    } else if ( type === ARTICLE_TYPE.SOFT_SKILL ) {
      return this._list.value.softSkills;
    } else {
      return this._list.value.techSkills;
    }
  }
}
