import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs';
import { Article, ARTICLE_TYPE, LinkedArticle } from 'src/app/shared/models/article.model';
import { ArticleService } from 'src/app/shared/services/article.service';

@Component( {
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.less'],
  encapsulation: ViewEncapsulation.None
} )
export class ArticleComponent implements OnInit {
  @Input() articleConfig: Article;

  public data: ArticleContent;
  public articleTypes = ARTICLE_TYPE;

  constructor (
    private _httpClient: HttpClient,
    private _articleService: ArticleService,
    private _router: Router ) {
  }

  ngOnInit(): void {
    this.loadContent();

    this._articleService.selectedArticle$
      .pipe( filter( a => a !== undefined ) )
      .subscribe( article => {
        this.articleConfig = { ...article };
        this.loadContent();
      } );
  }

  loadContent(): void {
    this._httpClient.get<ArticleContent>( this.articleConfig.contentPath ).subscribe( data => {
      this.data = { ...data };
      document.getElementById( 'content' ).innerHTML = data.content;
    } );
  }

  selectArticle( linkedArticle: LinkedArticle ) {
    this._articleService.setSelectedArticleByName( linkedArticle.articleName );
    this._router.navigate( [linkedArticle.routerLink] );
  }

  isSignature( name: string ) {
    // console.log( name )
    // return name.startsWith( 'signature' );
  }
}

export interface ArticleContent {
  content: string;
}