<div class="blog-container">
    <div class="navbar">
        <app-navigation></app-navigation>
    </div>
    <div class="content">
        <div class="cards-container">
            <ng-container *ngFor="let realization of realizations">
                <nz-card class="realization-card" [nzCover]="logo" (click)="selectRealization( realization )">
                    <nz-card-meta class="name" [nzTitle]="realization.displayedName" nzDescription=""></nz-card-meta>
                </nz-card>
                <ng-template #logo>
                    <div 
                        [ngClass]="realization.name.startsWith('signature') ? 'cover-container signature' : 'cover-container'" 
                        (click)="selectRealization( realization )"
                    >
                        <img [src]="realization.logoPath" [ngClass]="realization.displayedName ==='Signature Code' ? 'cover-img code' : 'cover-img'" />
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>