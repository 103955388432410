export interface Article {
    displayedName: string;
    name: string;
    logoPath: string;
    contentPath: string;
    type: ARTICLE_TYPE;
    linkedArticles: Array<LinkedArticle>;
}

export enum ARTICLE_TYPE {
    SOFT_SKILL = 'transverses',
    TECH_SKILL = 'techniques',
    REALIZATION = 'realisation'
}

export interface LinkedArticle {
    name: string;
    articleName: string;
    routerLink: string;
}