import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { FormationRoutingModule } from '../formation/formation-routing.module';
import { SkillsRoutingModule } from '../skills/skills-routing.module';
import { BlogRoutingModule } from '../blog/blog-routing.module';
import { ArticleComponent } from './article/article.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';


@NgModule( {
  declarations: [NavigationComponent, ArticleComponent],
  imports: [
    CommonModule,
    NzIconModule,
    NzMenuModule,
    NzDropDownModule,
    NzSpinModule,
    FormationRoutingModule,
    SkillsRoutingModule,
    BlogRoutingModule
  ],
  exports: [NavigationComponent, ArticleComponent]
} )
export class UiModule { }
