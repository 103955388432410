<div class="skills-container">
    <div class="navbar">
        <app-navigation></app-navigation>
    </div>
    <div class="content">
        <div class="card-container">
            <nz-card class="card" nzHoverable [nzCover]="techImage" (click)="goToTechSkills()">
                <nz-card-meta 
                    nzTitle="Compétences techniques" 
                    nzDescription="Découvrez mes compétences techniques">
                </nz-card-meta>
            </nz-card>
            <ng-template #techImage>
                <img src="../../../../assets/tech.png" />
            </ng-template>
        </div>
        <div class="card-container">
            <nz-card class="card soft" nzHoverable [nzCover]="softImage" (click)="gotToSoftSkills()">
                <nz-card-meta 
                    nzTitle="Compétences transverses" 
                    nzDescription="Découvrez mes compétences transverses">
                </nz-card-meta>
            </nz-card>
            <ng-template #softImage>
                <img src="../../../../assets/soft.png" />
            </ng-template>
        </div>
    </div>
</div>