import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SkillArticleComponent } from './pages/skill-article/skill-article.component';
import { SkillsListComponent } from './pages/skills-list/skills-list.component';
import { SkillsComponent } from './pages/skills/skills.component';

const routes: Routes = [
  {
    path: 'competences',
    component: SkillsComponent,
  },
  {
    path: 'competences/techniques',
    component: SkillsListComponent
  },
  {
    path: 'competences/transverses',
    component: SkillsListComponent
  },
  {
    path: 'competences/:skillName',
    component: SkillArticleComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SkillsRoutingModule { }
