import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.less']
})
export class SkillsComponent implements OnInit {

  constructor( private _router: Router ) { }

  ngOnInit(): void {
  }

  goToTechSkills() {
    this._router.navigate(['/competences/techniques']);
  }

  gotToSoftSkills() {
    this._router.navigate(['/competences/transverses']);
  }
}
