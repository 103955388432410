import { LocationStrategy, ViewportScroller } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component( {
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
} )
export class HomeComponent implements OnInit, OnDestroy {

  constructor (
    private _viewportScroller: ViewportScroller,
    private _locationStrategy: LocationStrategy,
    private _router: Router
  ) {
  }

  ngOnInit(): void {
    let fragment = this._router.url.substr( 2, this._router.url.length );
    if ( fragment.length === 0 ) {
      fragment = 'landing';
    }
    this._locationStrategy.pushState( {}, '', `#${fragment}`, '' );
    this._viewportScroller.scrollToAnchor( fragment );
    window.addEventListener( 'scroll', () => this.scrollToAnchor() );
  }

  scrollToAnchor() {
    if ( this._router.url.endsWith( '/' ) || this._router.url.startsWith( '/#' ) ) {
      const pos = this._viewportScroller.getScrollPosition();
      if ( pos[1] > ( window.innerHeight / 2 ) ) {
        this._locationStrategy.pushState( {}, '', '#profile', '' );
      } else {
        this._locationStrategy.pushState( {}, '', '#landing', '' );
      }
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener( 'scroll', () => this.scrollToAnchor() );
  }
}
