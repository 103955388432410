import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Article } from 'src/app/shared/models/article.model';
import { ArticleService } from 'src/app/shared/services/article.service';

@Component( {
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.less']
} )
export class BlogPageComponent implements OnInit {
  public selectedRealization: Article | undefined;

  constructor (
    private _articleService: ArticleService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
  }

  ngOnInit(): void {
    this._articleService.selectedArticle$
      .pipe( filter( a => a !== undefined ) )
      .subscribe( article => this.selectedRealization = { ...article } );

    this._activatedRoute.url.subscribe( url => {
      if ( !this.selectedRealization && !url[1] ) {
        this._router.navigate( ['/realisations'] );
      } else {
        const articleName = url[1].path;
        this._articleService.setSelectedArticleByName( articleName );
      }
    } );
  }
}
