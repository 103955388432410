<div class="formation-container">
    <div class="navbar">
        <app-navigation></app-navigation>
    </div>
    <div class="timeline">
      <nz-timeline nzMode="alternate">
        <ng-container *ngFor="let item of items; let index = index;">
          <ng-container *ngIf="item.isDiploma; else normal">
            <nz-timeline-item [nzDot]="diplomaTemplate">
              <div class="item-content" [ngClass]="index%2 === 0 ? 'right' : 'left'">
                <h4>{{item.title}}</h4>
                <p>{{item.description}}</p>
              </div>
            </nz-timeline-item>
          </ng-container>
          <ng-template #normal>
            <nz-timeline-item nzColor="green">
              <div class="item-content" [ngClass]="index%2 === 0 ? 'right' : 'left'">
                <h4>{{item.title}}</h4>
                <p>{{item.description}}</p>
              </div>
            </nz-timeline-item>
          </ng-template>
        </ng-container>
        </nz-timeline>
        <ng-template #diplomaTemplate>
          <img class="graduation-icon" src="../../../../assets/graduation.png" />
        </ng-template>
    </div>
</div>