<ul nz-menu nzMode="horizontal" nzTheme="dark" class="navbar">
    <li class="home" (click)="goToHome()">
        Arthur CHENG
    </li>
    <div class="item-menu">
        <li nz-menu-item [nzSelected]="isSelected('/home#landing')" (click)="goToProfile()">
            <span>À propos</span>
        </li>
        <li nz-menu-item [nzSelected]="isSelected('/parcours')" (click)="goToParcours()">
            <span>Parcours</span>
        </li>
        <li nz-menu-item [nzSelected]="isSelected('/competences')" routerLink="/competences" nz-dropdown [nzDropdownMenu]="skillsDropdown" [nzTrigger]="'click'">
            <span>Compétences</span>
            <nz-dropdown-menu #skillsDropdown>
                <ul nz-menu class="dropdown-menu">
                    <li class="child-item" nz-menu-item routerLink="/competences/techniques" [nzSelected]="isSelected('/competences-techniques')">
                        <span>Compétences techniques</span>
                    </li>
                    <li class="child-item" nz-menu-item routerLink="/competences/transverses" [nzSelected]="isSelected('/competences-transverses')">
                        <span>Compétences transverses</span>
                    </li>
                </ul>
            </nz-dropdown-menu>
        </li>
        <li nz-menu-item [nzSelected]="isSelected('/realisations')" routerLink="/realisations">
            <span>Réalisations</span>
        </li>
    </div>
</ul>