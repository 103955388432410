<div class="soft-skills-container">
    <div class="navbar">
        <app-navigation></app-navigation>
    </div>
    <div class="content">
        <div class="back">
            <a routerLink="/competences">
                <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
                <span>Retourner aux compétences</span>
            </a>

            <div class="cards-container">
                <ng-container *ngFor="let skill of displayedSkills">
                    <nz-card class="skill-card" [nzCover]="logo" (click)="selectSkill( skill )">
                        <nz-card-meta class="name" [nzTitle]="skill.displayedName" nzDescription=""></nz-card-meta>
                    </nz-card>
                    <ng-template #logo>
                        <div 
                            [ngClass]="displayedType === types.SOFT_SKILL ? 
                               ( skill.displayedName === 'Autonomie' ) ? 'cover-container soft autonomy' : 'cover-container soft'
                                : ( skill.displayedName === 'C#' ) ? 'cover-container c-sharp' : 'cover-container'"
                            (click)="selectSkill( skill )">
                            <img [src]="skill.logoPath" class="cover-img" />
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
</div>