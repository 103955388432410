import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Article } from 'src/app/shared/models/article.model';
import { ArticleService } from 'src/app/shared/services/article.service';

@Component( {
  templateUrl: './skill-article.component.html',
  styleUrls: ['./skill-article.component.less']
} )
export class SkillArticleComponent implements OnInit {
  public article: Article | undefined;

  constructor (
    private _articleService: ArticleService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this._articleService.selectedArticle$
      .pipe( filter( s => s !== undefined ) )
      .subscribe( skill => this.article = { ...skill } );

    this._activatedRoute.url.subscribe( url => {
      if ( !this.article && !url[1] ) {
        this._router.navigate( ['/competences'] );
      } else {
        const articleName = url[1].path;
        this._articleService.setSelectedArticleByName( articleName );
      }
    } );
  }
}
