import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Article, ARTICLE_TYPE } from 'src/app/shared/models/article.model';
import { ArticleService } from 'src/app/shared/services/article.service';

@Component( {
  templateUrl: './skills-list.component.html',
  styleUrls: ['./skills-list.component.less']
} )
export class SkillsListComponent implements OnInit {
  public softSkills: Array<Article>;
  public techSkills: Array<Article>;
  public displayedSkills: Array<Article>;
  public displayedType: ARTICLE_TYPE;
  public types = ARTICLE_TYPE;

  constructor (
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _articleService: ArticleService
  ) {

  }

  ngOnInit(): void {
    this._articleService.list$.pipe( filter( l => l !== undefined ) ).subscribe( list => {
      this.softSkills = [...list.softSkills];
      this.techSkills = [...list.techSkills];

      const urls = this._activatedRoute.snapshot.url;
      const path = urls[urls.length - 1].path;

      if ( path === ARTICLE_TYPE.SOFT_SKILL ) {
        this.displayedType = ARTICLE_TYPE.SOFT_SKILL;
        this.displayedSkills = this.softSkills;
      } else if ( path === ARTICLE_TYPE.TECH_SKILL ) {
        this.displayedType = ARTICLE_TYPE.TECH_SKILL;
        this.displayedSkills = this.techSkills;
      }
    } );

  }

  selectSkill( skill: Article ): void {
    this._articleService.setSelectedArticle( skill );
    this._router.navigate( [`/competences/${skill.name}`] );
  }
}