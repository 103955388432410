<div class="back" *ngIf="articleConfig">
    <ng-container *ngIf="articleConfig.type === articleTypes.SOFT_SKILL">
        <a routerLink="/competences/transverses">
            <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            <span>Retour</span>
        </a>
    </ng-container>
    <ng-container *ngIf="articleConfig.type === articleTypes.TECH_SKILL">
        <a routerLink="/competences/techniques">
            <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            <span>Retour</span>
        </a>
    </ng-container>
    <ng-container *ngIf="articleConfig.type === articleTypes.REALIZATION">
        <a routerLink="/realisations">
            <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            <span>Retour</span>
        </a>
    </ng-container>
</div>
<div class="article" *ngIf="articleConfig">
    <div class="article-name">
        <span>{{ articleConfig.displayedName }}</span>
    </div>
    <div class="logo-container">
        <img [src]="articleConfig.logoPath" />
    </div>
    <div id="content" class="content">
    </div>
    <div class="footer">
        <h2 class="title">Articles liés :</h2>
        <ul>
            <ng-container *ngFor="let article of articleConfig.linkedArticles">
                <li>
                    <span (click)="selectArticle(article)">{{ article.name }}</span>
                </li>
            </ng-container>
        </ul>
    </div>
</div>