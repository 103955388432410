import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateTime, Duration } from 'luxon';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component( {
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
} )
export class ProfileComponent implements OnInit {

  public showModal: boolean = false;
  public age: number;
  public form!: FormGroup;

  constructor (
    private _formBuilder: FormBuilder,
    private _nzMessageService: NzMessageService
  ) {
    const diff = DateTime.fromISO( '19940925' ).diffNow( 'year' );
    this.age = Math.round( Math.abs( diff.years ) );
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group( {
      email: ['', Validators.required],
      message: ['', Validators.required]
    } );
  }

  sendMail(): void {
    if ( this.form.valid && this.form.get( 'email' ).value.includes( '@' ) ) {
      this._nzMessageService.success( 'Votre message a bien été envoyé. Je vous répondrais le plus rapidement possible.' );
      this.showModal = false;
    } else {
      this._nzMessageService.error( 'Veuillez remplir tous les champs.' );
    }
  }
}
