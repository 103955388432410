import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogListComponent } from './pages/blog-list/blog-list.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';

const routes: Routes = [
  {
    path: 'realisations',
    component: BlogListComponent
  },
  {
    path:'realisations/:realization',
    component: BlogPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogRoutingModule { }
