<section class="landing">
    <div class="landing-content">
        <div class="glass">
            <h1>Arthur CHENG</h1>
            <h3>Développeur Full Stack</h3>
        </div>
    </div>
    <div class="arrow" (click)="goToProfile()">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</section>