import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './pages/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SkillsModule } from '../skills/skills.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { FormationModule } from '../formation/formation.module';
import { NzCardModule } from 'ng-zorro-antd/card';
import { BlogModule } from '../blog/blog.module';
import { ProfileComponent } from './components/profile/profile.component';
import { LandingComponent } from './components/landing/landing.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { UiModule } from '../ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule } from "ng-zorro-antd/message";

@NgModule( {
  declarations: [HomeComponent, NavbarComponent, ProfileComponent, LandingComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzCardModule,
    NzDropDownModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzLayoutModule,
    NzMessageModule,
    NzModalModule,
    NzMenuModule,
    HomeRoutingModule,
    UiModule
  ]
} )
export class HomeModule { }
