<section class="profile-container">
    <div class="content">
        <!-- <div class="photo"></div> -->
        <div class="text-content">
            <h1>À propos</h1>
            <p class="description">
                <span class="tab"></span>
                Après un parcours scolaire hésitant, j'ai décidé de m'orienter vers le domaine qui m'a toujours fasciné : le développement informatique.
                En effet, après des années à suivre un parcours scolaire général, 
                pendant lesquelles je ne me sentais pas à ma place, certainement dû au fait que la majorité des professeurs n'a pas réussi à susciter l'intérêt des élèves
                et donc, qui ne me motivait pas à m’investir, 
                j’ai pris la décision de reprendre mes études dans le domaine qui me semblait pouvoir satisfaire ma curiosité. 
                J’aime le fait que le développement informatique peut s’appliquer à tous les autres secteurs d’activité.
                <br/>
                <br/>
                <span class="tab"></span>
                Je suis aujourd'hui <span class="bold">développeur full stack</span>. 
                Je travaille dans l'environnement <span class="bold">.NET</span>, 
                couplé au framework <span class="bold">Angular</span> et <span class="bold">TypeScript</span> 
                ( ainsi que d'autres frameworks JavaScript dans une moindre mesure ), 
                au sein de <span class="bold">Signature Code</span>.
                <br/>
                Dans ma vie personnelle, comme dans mon environnement professionnel, je suis curieux, sérieux, rigoureux et persévérant.
                Je peux dire que je m'épanouis dans une équipe qui partage des valeurs qui sont importantes pour moi : le respect, la bienveillance et l'entraide.
                <br/>
                <br/>
                <span class="tab"></span>
                Au-delà du développement informatique, d'autres passions animent mon temps libre. Je suis passionné de football, de films et de series.
                En outre, voyager et découvrir de nouvelles cultures, c'est ce qui me stimule et qui me permet de me ressourcer, afin de me recentrer sur ce qui est important.
                J'ai eu l'occasion de voyager dans plusieurs pays, répartis sur la surface du globe.
            </p>
        </div>
        <div class="contact">
            <h1>Contact</h1>
            <div class="icons">
                <div (click)="showModal = !showModal">
                    <i nz-icon nzType="mail" nzTheme="fill"></i>
                </div>
                <!-- <a href="mailto:acheng@et.intechinfo.fr">
                    <i nz-icon nzType="mail" nzTheme="fill"></i>
                </a> -->
                <a href="https://www.linkedin.com/in/arthur-cheng-2398a415a">
                    <i nz-icon nzType="linkedin" nzTheme="fill"></i>                    
                </a>
            </div>
        </div>
    </div>
</section>

<nz-modal [(nzVisible)]="showModal" nzTitle="Contact" (nzOnOk)="sendMail()" (nzOnCancel)="showModal = false;">
    <ng-container *nzModalContent>
        <form nz-form [formGroup]="form">
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzFor="email">E-mail</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input formControlName="email" style="width: 100%" nz-input type="email">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzFor="message">Votre message</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <textarea formControlName="message" style="width: 100%" rows="8" nz-input></textarea>
                </nz-form-control>
            </nz-form-item >
        </form>
    </ng-container>
</nz-modal>