import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.less']
})
export class LandingComponent implements OnInit {

  constructor( private _viewportScroller: ViewportScroller ) { }

  ngOnInit(): void {
  }
  
  goToProfile() {
    this._viewportScroller.scrollToAnchor('profile');
  }
}
